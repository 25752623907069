.home {
    height: 100vh;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        font-size: 3rem;
        text-align: center;
    }

    .icon {
        max-height: 60vh;

        img {
            max-width: 100%;
            height: auto;
            max-height: 60vh;
        }
    }

    .links {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        flex-wrap: wrap;

        @media screen and (max-width: 768px) {
            flex-direction: column;
           a {
                padding: 5px;
           }
            
        }

        a {
            margin: 0 1rem;
            font-size: 1.5rem;
            color: #000;
            text-decoration: none;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: #f00;
            }
        }
    }
}
